
/* You can add global styles to this file, and also import other style files */
@use 'variables';
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$MCP-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$MCP-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$MCP-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$MCP-theme: mat.m2-define-light-theme((
  color: (
    primary: $MCP-primary,
    accent: $MCP-accent,
    warn: $MCP-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($MCP-theme);

@font-face {
  font-family: 'Material Icons';
  src: url('../assets/fonts/MaterialIcons/MaterialIcons-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf");
}
  
@font-face {
  font-family: Roboto;
  font-weight: 600;
  src: url("../assets/fonts/Roboto/Roboto-Bold.ttf");
}

@font-face {
  font-family: Roboto;
  font-weight: 100;
  src: url("../assets/fonts/Roboto/Roboto-Light.ttf");
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

.bg-gray {
  background-color: variables.$background-gray;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

::ng-deep .mat-mdc-form-field-subscript-wrapper {
  height: 0 !important;
}

.mat-icon {
  font-family: 'Material Icons' !important;
  font-style: normal;
  font-weight: normal;
  font-size: 24px; /* Standardgröße für Material Icons */
  line-height: 1;
}

.proceed-button {
  margin-top: 1rem;
}

.page-description {
  margin: 10px;
}

.card-element-padding {
  padding: 10px;
}
@use 'variables';
.snackbar-default.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: variables.$blue-color;
}

.snackbar-warn.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: variables.$orange-color;
}

.snackbar-ok.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: variables.$green-color;
}
$disabled-percentage: 0.5;

$gray-light-color: #b4b1aa;
$gray-dark-color: #4e4d42;
$gray-separator: #c6c6c8;

$gray-text-color: rgba(60, 60, 67, 0.6);

$orange-color: #f4a506;
$green-color: #49bf84;
$white-color: #fff;
$blue-color: #3c99d5;
$yellow-color: #ffcc00;
$red-color: #eb5e65;
$black-color: #000;

$text-color-info: #237cae;
$text-color-gray: #3c3c43;

$background-gray: #f5f5f5;

$font-title: 22px;
$font-subtitle: 12px;

$color-cross: #8e8e93;
$color-circle-fill: rgba(#252525, 0.78);

$topbar-height: 60px;
